<template>
  <div>
    <el-card class="flex-def flex-zCenter flex-cCenter">
      <el-form style="width: 40rem">
        <el-form-item label="全部分类图标">
          <y_upload_img v-model="form.cate_all_icon"></y_upload_img>
        </el-form-item>
        <el-form-item align="right">
          <el-button @click="edit" type="primary">保存</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>
<script>
import y_upload_img from "@/components/y_upload_img";

export default {
  components:{y_upload_img},
  data() {
    return {
      form: {
        cate_all_icon: "",
      }
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    edit() {
      this.$api.plugin.shop.settingBasicEdit(this.form).then(() => {
        this.$message.success("操作成功")
        this.load();
      })
    },
    load() {
      this.$api.plugin.shop.settingBasic().then(res => {
        this.form = res;
      })
    }
  }
}
</script>